* {-webkit-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after {-webkit-box-sizing:border-box;box-sizing:border-box;}
*,*:before,*:after {-webkit-tap-highlight-color:rgba(0,0,0,0);}
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {border:0 none;font-size:inherit;color:inherit;margin:0;padding:0;vertical-align:baseline;text-decoration:none;}
h1,h2,h3,h4,h5,h6 {font-weight:normal;}
em,strong {font-style:normal;}
ul,ol,li {list-style:none;}
body{
	
}

.bigbox{
	width: 100%;
	min-height:100vh;
	max-height:100vh;
	max-width: 10rem;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-flow: column;
	background: #f5f5f5;
}
header{
	width: 100%;
	height:1rem;
	/*background: red;*/
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
header div{
	width: 50%;
	height: 0.54rem;
	/*background: red;*/
	display: flex;
	justify-content: center;
}
header div>img{
	display: block;
	height: 100%;
}
.main{
	flex: auto;
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	margin-top: 0.2rem;
}
footer{
	width: 100%;
	height: 1.58rem;
	/*background: bisque;*/
	display: flex;
	padding: 0 0.5rem;
	z-index: 10;
}
.swiper-container{
	padding: 0.7rem 21%;
	width: 100%;
	/*max-height: 7.2rem;*/
}
.swiper-wrapper .main_list{
	padding: 0.2rem;
	background: white;
	width: 50%;
	display: flex;
	flex-flow:column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2);
}
/*轮播效果*/
.swiper-slide{
	width: 50%;
}



.main_list_center{
	width: 100%;
}
.main_list_center img{

	display: block;
	width: 100%;
	margin: 0 auto;
}
.main_list_word {
	width: 100%;
	padding: 0.10rem 0;
}
.main_list_word img{
	display: block;
	width: 100%;
	margin: 0 auto;
}

.main_list_foot{
	width: 100%;
	height: 0.54rem;
	background: #262626;
	font-size: 0.32rem;
	text-align: center;
	line-height: 0.54rem;
	color: white;
}
.footleft{
	width: 0.48rem;
	display: flex;
	align-items: center;
}
.footcenter{
	/*background: red;*/
	flex: 1;
	display: flex;
	justify-content: center;
}
.footcenter_btn{
	width: 1.2rem;
	height: 1.2rem;
	/*background: #262626;
	border-radius: 50%;*/
}
.footcenter_btn img{
	width: 100%;
	display: block;
}
.footleft_box{
	width: 100%;
	height: 0.48rem;
	/*background: blue;*/
}
.footleft_box img{
	width: 100%;
	display: block;
}
.footright{
	width: 0.48rem;
	display: flex;
	align-items: center;
}
.footright_box{
	width: 100%;
	height: 0.48rem;
	/*background: blue;*/
}
.footright_box img{
	width: 100%;
	display: block;
}

#myPanel{
	width: 100%;
	background: #262626;
	opacity: 0.95;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow:column;
	font-size: 0.36rem;
	color: white;
	/*position: relative;*/
}
.ui-panel-inner{
	width: 100%;
	height: 100vh;
	display: flex;
	flex-flow: column;
}

.panel_center{
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow:column;
	font-size: 0.36rem;
	color: white;
}

/*分享遮罩层*/
/*.panel_b{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.8);
	color: #000000;
	z-index: 999;
	display: none;
}
.sharebox{
	width: 100%;
	height:6rem;
	position: absolute;
	left: 0;
	bottom: 0;
	background: white;
	padding: 1rem;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}
.sharebox_1,.sharebox_2{
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.sharebox_3{
	display: flex;
	justify-content:center;
}
.share_btn{
	width: 1rem;
	height: 1rem;
	background: red;
}*/

#myPanel p{
	font-weight: normal;
	padding: 0.4rem 0;
}
#myPanel a{	
	font-weight: normal;
	text-decoration: none;
}
#myPanel a:hover{
	color: white;
}
.myPanel_a{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1rem;
	height: 1rem;
	border: 1px solid white;
	margin-top: 0.4rem;
}

#bottom_box{
	width: 100%;
	height: 0.2rem;
	background: red;
}
#twoCode,#fourCode,#threeCode{
	font-size: 0.36rem;
	margin: 0 auto;
	text-align: center;
	display: none;
	border-radius: 0;
}
/*#threeCode{
	font-size: 0.48rem;
	margin: 0 auto;
	text-align: center;
}
#fourCode{
	font-size: 0.48rem;
	margin: 0 auto;
	text-align: center;
}*/
.twoCode_box{
	width:100%;
	height: 3.4rem;
	margin:0.5rem 0 0.2rem 0;
	
}
.twoCode_box img{
	display: block;
	width: 3.4rem;
	height: 3.4rem;
	margin: 0 auto;
	text-align: center;
}
.twoCode_p3{
	font-size: 0.32rem;
}

/*关于页面*/
#aboutPop-popup{
	width: 100%;
	height: 100%;
	background: white;
}
#aboutPop{
	height: 100%;
	font-size: 0.32rem;
	color: #262626;
	display: flex;
	flex-flow: column;
	/*justify-content: center;*/
	padding: 0.5rem;
}
#aboutPop a{
	font-weight: normal;
	text-decoration: none;
}
#aboutPop a:hover{
	color: #262626;
}

#aboutPop p{
	text-align: justify;
}
.aboutPop_a{
	
}
.aboutPop_a_top{
	width: 100%;
	text-align: center;
	padding: 0.5rem 0;
}
.aboutPop_a_top img{
	width: 70%;
}
/*关于页面*/
.pagetwoBox{
	min-height: 100%;
	font-size: 0.32rem;
	/*display: flex;
	flex-flow: column;*/
	/*justify-content:space-between;*/
	color: #262626;
	padding:2em;
}
.pagetwoBox a{
	font-weight: normal;
	text-decoration: none;
}
.pagetwoBox a:hover{
	color: #262626;
}
.pagetwoBox p{
	text-align: justify;
	font-weight: normal;
}
#header_two{
	/*height: 0;*/
	font-size: 0.28rem;
}
/*百度分享*/
.bdsharebuttonbox .share_btn a{
	display: block;
	width: 100%;	
	height: 100%;
}
.bds_weixin{
	background: url(../images/share_btn_photo.png);
	background-size: 100% 100%;
}
.bd_weixin_popup_bg{
	width: 50%;
}
